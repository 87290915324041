@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

:root {
  --green: #6ac28c;
  --blue: #394ca0;
  --orange: #f59c60;
  --red: #e6483f;
  --pink: #f38fb0;
  --logo-height: min(20vh, 22vw);
  --font-size: 20px;
  --caption-size: max(min(3vh, 4.5vw), var(--font-size));
  --button-size: min(5vh, 10vw);
  --header-padding: calc(var(--caption-size) * 2);
  --shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  --max-card-width: calc(100vw - var(--header-padding) * 2);
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

body {
  font-family: "DM Sans", sans-serif;
  background-color: var(--pink);
  position: relative;
}

button {
  background-color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: -10px;
  color: black;
}

button:hover {
  background-color: #eeeeee;
}

input {
  border: #eeeeee 2px solid;
  border-radius: 4px;
  padding: 8px;
  font-size: var(--font-size);
}

input:focus {
  outline: none;
}

a {
  text-decoration: inherit; /* no underline */
  color: black;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.header {
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  padding: var(--header-padding);
}

@media only screen and (min-width: 600px) {
  .caption {
    max-width: 100%;
  }
}

.caption {
  max-width: calc(var(--caption-size) * 25);
  color: white;
  font-size: var(--caption-size);
  pointer-events: none;
  margin-bottom: 10px;
}

.emoji-button-container {
  justify-content: end;
  align-items: flex-end;
  cursor: pointer;
  position: relative;
}

.emoji-button:hover {
  border: none;
  background-color: white;
}

.emoji-button {
  background-color: white;
  border: white 2px solid;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--button-size);
  height: var(--button-size);
  margin: calc(var(--button-size) * 0.1);
  padding: calc(var(--button-size) * 0.2);
  border-radius: calc(var(--button-size) * 0.2);
  box-shadow: var(--shadow);
}

.emoji-button > img {
  max-width: 100%;
  max-height: 100%;
}

.card {
  position: absolute;
  justify-content: space-between;
  top: 0;
  right: 0;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  box-shadow: var(--shadow);
  font-size: var(--font-size);
  max-width: var(--max-card-width);
}

.card-content {
  justify-content: space-between;
  padding-right: 10px;
}

.card-footer {
  margin-top: 40px;
  width: min(200px, calc(var(--max-card-width) - 60px));
}

.link-button {
  background-color: white;
  border: none;
  font-size: var(--font-size);
  text-align: left;
}

.link-button > img {
  margin-top: 6px;
  margin-left: 6px;
  height: calc(var(--font-size) * 0.7);
  width: calc(var(--font-size) * 0.7);
}

.close-button {
  width: 40px;
  height: 40px;
}

.close-button > img {
  width: 20px;
  height: 20px;
}

.chat-box {
  margin-top: 10px;
}

.chat-content {
  justify-content: space-between;
}

.chat-card {
  height: 400px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s linear;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100vw);
  }
  80% {
    transform: translateX(0px);
  }
  90% {
    transform: translateX(-1vw);
  }
  100% {
    transform: translateX(0vw);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100vw);
  }
  80% {
    transform: translateX(0px);
  }
  90% {
    transform: translateX(1vw);
  }
  100% {
    transform: translateX(0vw);
  }
}

@keyframes l {
  0% {
    transform: translateX(-100vw) rotateZ(-16deg);
  }
  40% {
    transform: translateX(0px) rotateZ(-16deg);
  }
  45% {
    transform: translateX(1vw) rotateZ(-16deg);
  }
  55% {
    transform-origin: bottom left;
    transform: translateX(0px) rotateZ(4deg);
  }
  60% {
    transform-origin: bottom left;
    transform: rotateZ(0deg);
  }
}

.slide-in-right {
  animation: slideInFromRight 1s ease-out;
}

.slide-in-left {
  animation: slideInFromLeft 1s ease-out;
}

.l {
  animation: l 2s ease-out;
}

.logo {
  position: fixed;
  bottom: calc(var(--logo-height) / 2);
  left: calc(var(--logo-height) / 3);
  pointer-events: none;
  filter: invert(100%);
}

.logo > img {
  height: var(--logo-height);
}

.logo > .p,
.y,
.a {
  padding-right: calc(var(--logo-height) * 0.05);
}

.p,
.y {
  margin-top: calc(var(--logo-height) / (10 / 3));
  margin-bottom: calc((var(--logo-height) / (10 / 3)) * -1);
}
